import React, { useEffect, useState } from 'react'
import { api_url, logo, } from '../config/Config'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _fetch from '../config/api';
import toasted from '../config/toast';
import { useTranslation } from 'react-i18next';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useLanguage } from '../LanguageContext';
import { menuItems } from './MenuItems';
import { DoubleArrow, KeyboardArrowDown } from '@mui/icons-material';

const Sidebar = () => {

  const { t } = useTranslation();
  const { selectedLanguage, changeLanguage } = useLanguage();

  let location = useLocation();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [userData, setuserData] = useState({ user_info: { email: '' } });

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  const navigate = useNavigate();



  const [sponsorId, setSponsorId] = useState(null);

  // const [sponsorId, setSponsorId] = useState('');

  useEffect(() => {
    var getUseranme :any =  localStorage.getItem("userName");
    setSponsorId(getUseranme)
    // if (auth === "true") {

    // }
    let userDetails: any = (localStorage.getItem("user"));
    var storeData = JSON.parse(userDetails)
    // console.log('userDetails',storeData);

    setuserData(storeData);
    let storeLanguage = localStorage.getItem("language");

    if (storeLanguage) {
      data?.map((val: any) => {
        if (storeLanguage == val.value) {
          setselectLangSelect(val?.short);
          changeLanguage(val.value);
        }
      })
      // setselectLangSelect(storeLanguage)
      // console.log('storeLanguage',storeLanguage?.value);
    }
  }, [location.pathname]);



  const ToggleButtonRemover = () => {
    const body = document.body;
    body.classList.remove("show-sidebar");
  }

  const [theme, setTheme] = useState('light');

  const modechange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
    // setTheme(theme == 'light' ? 'dark' : 'light');

    localStorage.setItem('mode', theme);
  }

  // console.log("theme", theme);


  let modeTheme = localStorage.getItem("mode");

  useEffect(() => {
    const body = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);

  }, [theme]);



  const data = [
    {
      value: 'en',
      text: 'English',
      short: 'En'
    },
    {
      value: 'fr',
      text: 'French',
      short: 'Fr'

    },
    {
      value: 'arabic',
      text: 'Arabic',
      short: 'Ar'

    },
    {
      value: 'chinese',
      text: 'Chinese',
      short: 'Ch'

    },
    {
      value: 'german',
      text: ' German',
      short: 'Gr'

    },
    {
      value: 'hindi',
      text: 'Hindi',
      short: 'Hi'

    },

  ];
  const [selectLangSelect, setselectLangSelect] = useState('En');

  // const changeLanguageInContext = (language: any) => {
  //   setselectLangSelect(language?.short)
  //   changeLanguage(language?.value);
  //   localStorage.setItem('language', language?.value);
  // };



  const [activeKey, setActiveKey] = useState("0");

  useEffect(() => {
    const storedActiveKey = localStorage.getItem('activeTab');
    if (storedActiveKey) {
      setActiveKey(storedActiveKey);
    }
  }, []);



  const [activeEventKey, setActiveEventKey] = useState(localStorage.getItem('activeEventKey') || "0");


  // useEffect(() => {
  //   const pathToEventKeyMap: { [key: string]: string } = {};
  //   MenuItems.forEach(item => {
  //     if (item.subMenu) {
  //       item.subMenu.forEach(MenuItemssubItem => {
  //         pathToEventKeyMap[subItem.path] = item.eventKey;
  //       });
  //     } else if (item.path) {
  //       pathToEventKeyMap[item.path] = item.eventKey;
  //     }
  //   });

  //   const currentEventKey = pathToEventKeyMap[location.pathname];
  //   if (currentEventKey && currentEventKey !== activeEventKey) {
  //     setActiveEventKey(currentEventKey);
  //   }
  // }, [location.pathname, menuItems, activeEventKey]);

  useEffect(() => {
    localStorage.setItem('activeEventKey', activeEventKey);
  }, [activeEventKey]);

  const handleAccordionClick = (eventKey: string) => {
    if (activeEventKey !== eventKey) {
      setActiveEventKey(eventKey);
    }
  };



  return (
    <>
      <div className="app-sidebar">
        <div className="app-sidebar3">
          <div className="sidebar">
            <div className="app-sidebar__logo">
              <Link className="header-brand" to="/dashboard">
                <div className="mode">
                  <img src={logo} className="header-brand-img light-logo" alt="logo" />
                </div>
              </Link>

            </div>


            <ul className="sidebar_menu">
              <Accordion defaultActiveKey={activeEventKey} flush>
                {menuItems.map(({ eventKey, path, icon, label, subMenu, onClick }) => (
                  <Accordion.Item
                    eventKey={eventKey}
                    key={eventKey}
                    onClick={() => handleAccordionClick(eventKey)}
                    className={activeEventKey === eventKey ? 'active-Item' : ''}>
                    <Accordion.Header className={subMenu ? '' : 'only_button'}>
                      {subMenu ? (
                        <span>{icon}{t(label)}</span>
                      ) : (
                        <Link to={path!} onClick={() => { ToggleButtonRemover(); if (onClick) onClick(); }}
                          className="drop_link">
                          {icon}{t(label)}
                        </Link>
                      )}
                    </Accordion.Header>
                    {subMenu && (
                      <Accordion.Body>
                        <ul>
                          {subMenu.map(({ path, icon, label }) => (
                            <li key={path}>
                              <Link onClick={ToggleButtonRemover} to={path == "team-tree" ? `/team-tree/${sponsorId}` : path}>
                                <i>{icon}</i> {t(label)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    )}
                  </Accordion.Item>
                ))}
              </Accordion>
            </ul>
          </div>


        </div>
      </div>
      <div className='sidbar-overly' onClick={ToggleButtonRemover}></div>
    </>
  )
}

export default Sidebar