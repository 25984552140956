import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import SupportTab from "./SupportTab";
import _fetch from "../config/api";
import { api_url } from "../config/Config";
import toasted from "../config/toast";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const UploadDetails: React.FC = () => {

    const [state, setState] = useState("");
    const [frontPreview, setFrontPreview] = useState<string | null>(null);
    const [backPreview, setBackPreview] = useState<string | null>(null);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: "front" | "back") => {



        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const previewUrl = URL.createObjectURL(file);
            if (type === "front") {
                setFrontPreview(previewUrl);
            } else {
                setBackPreview(previewUrl);
            }
        }
    };

    const handleBoxClick = (type: "front" | "back") => {
        const fileInput = document.getElementById(type === "front" ? "frontFile" : "backFile") as HTMLInputElement;
        if (fileInput) fileInput.click();
    };

    const [frontEndImage, setfrontEndImage] = useState("");
    const [backEndImage, setbackEndImage] = useState("");


    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const compressAllImage = async (event: any, state: any) => {
        const checkCondition = event.target.files && event.target.files?.[0]?.size <= 1000000
        if (checkCondition) {
            const file = event.target.files[0]
            let base64 = await convertBase64(file);
            state(base64)
        } else {
            toasted.error('Please upload Image less than 1MB!');

        }

    }


    const [name, setname] = useState("");
    const [phone, setphone] = useState("");
    const [country, setCountry] = useState<string>('India');
    const [region, setRegion] = useState<string>('');
    const [Nationalnumber, setNationalnumber] = useState("");



    const [userDetails, setuserdetails] = useState({
        username: "",
        phone: "",
        sponsor_id: "",
        name: "",
        rank: 0
    });

    const userDataFound = async () => {
        const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
        if (data1.status === 'success') {
            setuserdetails(data1?.data?.data);
        }
    }
    const [lbdUserDetails, setlbdUserdetails] = useState({
        status: false,
        result: { status: '' }
    });
    const ldpDetails = async () => {
        const data1 = await _fetch(`${api_url}user/ldp-details`, "GET", {});

        setlbdUserdetails(data1);

    }

    useEffect(() => {
        userDataFound()
        ldpDetails();
    }, [])

    const [BtnDisable, setBtnDisable] = useState(false)

    const updatePassword = async (e: any) => {
        e.preventDefault();
        setBtnDisable(true);
        let data = {
            "username": userDetails?.username,
            "name": name,
            "upline": userDetails?.sponsor_id,
            // "country": country,
            "state": region,
            "phone": phone,
            // "id_front": frontEndImage,
            // "id_back": backEndImage
            idproofNumber: Nationalnumber
        }
        let result = await _fetch(`${api_url}user/ldp-program`, "POST", data, {})
        if (result?.status === "success") {
            toasted.success(result?.message);
            setBtnDisable(false);
        }
        else {
            toasted.error(result?.message);
            setBtnDisable(false);
        }
    }





    const selectCountry = (val: string) => {
        setCountry(val);
    };

    const selectRegion = (val: string) => {
        setRegion(val);
    };

    return (
        <main>
            <SupportTab links={[{ name: 'Upload Details', path: '#' }]} />

            <div className="support_main">
                <div className="support_inner mb-4">
                    <div className='card-header'>
                        <p> Upload Details</p>
                    </div>

                    <div className="support-section">
                        {userDetails?.rank >= 2 && lbdUserDetails?.status == false ? <form className="__upload-form" onSubmit={updatePassword}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="idNumber" className="form-label">
                                            ID Number
                                        </label>
                                        <input type="number" className="form-control" id="idNumber" value={userDetails?.username} readOnly />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="name" className="form-label">
                                            Name
                                        </label>
                                        <input type="text" className="form-control" id="name" value={name} onChange={(e: any) => setname(e.target.value)} />
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="uplineName" className="form-label">
                                            Upline Name
                                        </label>
                                        <input type="text" className="form-control" id="uplineName" value={userDetails?.sponsor_id} readOnly />
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="mobileNo" className="form-label">
                                            Mobile Number
                                        </label>
                                        <input type="tel" className="form-control" max={10} maxLength={10} pattern="\d{10}" value={phone} onChange={(e: any) => setphone(e.target.value)} id="mobileNo" />
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="country" className="form-label"> Country </label>
                                        <CountryDropdown value={country} onChange={(val: string) => selectCountry(val)} id='country' classes="form-control" />
                                    </div>
                                </div> */}

                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="state" className="form-label"> Region </label>
                                        <RegionDropdown country={country} value={region} onChange={(val: string) => selectRegion(val)} classes="form-control" />
                                    </div>
                                </div>


                                <div className="col-12 mt-3">
                                    <div className="input-group">
                                        <label htmlFor="nationalCardNumber" className="form-label">
                                            National Card/ Aadhar Card Number
                                        </label>
                                        <input type="text" className="form-control" id="nationalCardNumber" onChange={(e: any) => { setNationalnumber(e.target.value) }} value={Nationalnumber} />
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="frontFile" className="form-label">
                                            Upload Front Side of National Card
                                        </label>

                                        <input style={{ display: 'none' }} type="file" className="form-control" id="frontFile" onChange={(e) => { handleFileUpload(e, "front"); compressAllImage(e, setfrontEndImage) }} />

                                        <div onClick={() => { handleBoxClick("front") }} className="__boxupload" >
                                            {frontPreview ? (
                                                <img
                                                    src={frontPreview}
                                                    alt="Front Preview"
                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                />
                                            ) : (
                                                <span>Upload Front File</span>
                                            )}
                                        </div>
                                    </div>

                                </div>


                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="backFile" className="form-label">
                                            Upload Back Side of National Card
                                        </label>
                                        <input style={{ display: 'none' }} type="file" className="form-control" id="backFile" onChange={(e) => { handleFileUpload(e, "back"); compressAllImage(e, setbackEndImage) }} />

                                        <div onClick={() => handleBoxClick("back")} className="__boxupload" >
                                            {backPreview ? (
                                                <img src={backPreview} alt="Back Preview" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                            ) : (
                                                <span>Upload Back File</span>
                                            )}
                                        </div>

                                    </div>


                                </div> */}
                            </div>


                            <div className="btn-sec">
                                <button type="submit" className='new_button_css_dashboard' disabled={BtnDisable}  > {BtnDisable ? 'Please wait ...' : "Submit"} </button>
                            </div>
                        </form> : <div className="text-center ">
                            {userDetails?.rank < 2 && <h3 className="text-danger">You are not eligible for ldp program </h3>}
                            {lbdUserDetails?.status == true && <p className="text-info"> Your LPD Program status is {lbdUserDetails?.result?.status} </p>}
                        </div>}
                    </div>
                </div>
            </div>
        </main>

    );
};

export default UploadDetails;
